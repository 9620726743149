import React from "react";
import Layout from "../components/Layout/Index";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Decboo1 from "../components/decbooone";
import Decboo2 from "../components/decbootwo";
import Decboo3 from "../components/decboothree";

const OurDesignStudios = () => {
  return (
    <>
      <div style={{ boxShadow: "-7px 7px 45px 0px rgba(0, 0, 0, 0.1)" }}>
        <Header />
      </div>
      <p className="Submainhead">Decors Book</p>

      <p className="text-center m-3 h5 dbook-links"><a style={{color:"grey"}} href="/decor-books">2021</a> <a style={{color:"grey"}} href="/decor-book-2">2020</a> <a href="/decor-book-3">2019</a></p>

      <div className="Imgheight">
        <Tabs defaultActiveKey="first">
          <Tab eventKey="first" title="">
            <Decboo3 />
          </Tab>
         
        </Tabs>
      </div>

      <Footer />
    </>
  );
};

export default OurDesignStudios;
