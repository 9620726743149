import React from 'react'
import Layout from '../components/Layout/Index'
import Header from "../components/Header";
import Footer from "../components/Footer";

const About = () => {
  return (
    <>
    <div className='headerboxshadow'>
      <Header />
      </div>    <div className='contactbody'>
    
        <div className='contactbody2' >
        <p className='subheadmain' >Contact Us</p>
        <br/>
        
       
        <p className='subheadmain' >Head Office</p> <br/>
        <p className='contacttext'>Al-Noor Building,</p>
        <p className='contacttext'>96A, SMCHS. Karachi</p>
        
        <br/>
      
        <p className='contacttext'>+92 21 34389272</p>
        <p className='contacttext'>+92 21 34392120</p>
        <p className='contacttext'>+92 21 34559853</p>

        </div>
    </div>

      <Footer  />
      </>
  )
}

export default About