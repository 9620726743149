import { transform } from "framer-motion";
import React , { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from 'axios';
import { useSearchParams } from "react-router-dom";


import $ from 'jquery';

const key = 1;

const Decorslist = () => {

  const loadMore = async () => {


    $('.tabNav ul').each(function() {
      var num = $(this).find('li').length;
      if (num > 3) {
        $(this).find('li').addClass('hidden');
        $(this).find('li').addClass('toggle');
        $(this).find('li:first').removeClass('hidden');
        $(this).find('li:first').removeClass('toggle');
        $(this).find('li:first').addClass('toggled');
        $(this).find('li:nth-child(1)').removeClass('hidden');
        $(this).find('li:nth-child(1)').removeClass('toggle');
        $(this).find('li:nth-child(1)').addClass('toggled');
        $(this).find('li:nth-child(2)').removeClass('hidden');
        $(this).find('li:nth-child(2)').removeClass('toggle');
        $(this).find('li:nth-child(2)').addClass('toggled');
        $(this).find('li:nth-child(3)').removeClass('hidden');
        $(this).find('li:nth-child(3)').removeClass('toggle');
        $(this).find('li:nth-child(3)').addClass('toggled');
        $(this).find('li:nth-child(4)').removeClass('hidden');
        $(this).find('li:nth-child(4)').removeClass('toggle');
        $(this).find('li:nth-child(4)').addClass('toggled');
      }
    });

    $('.more').on('click', function() {

        $('.toggle').toggleClass('hidden');

    });

  };

  useEffect(() => {

    $('input:checkbox').on('change', function () {

      var resultUrl = "";

      var url_all = "https://alfinaltwo.reforce.com.pk/alnoor/all-products";
        
      var mc_array = [];
      var sc_array = [];
        
      var api = "https://alfinaltwo.reforce.com.pk/api/all-products?";
    
      mc_array = [];
      sc_array = [];

      $("input:checkbox:checked").each(function() {
        if ($(this).hasClass('m-cat')){
          mc_array.push($(this).attr('rel'));
        }
        if ($(this).hasClass('s-cat')){
          sc_array.push($(this).attr('rel'));
        }
      });

      resultUrl = api + "c_id=" + mc_array.join(",") + "&sc_id=" + sc_array.join(",")
      //alert(url);

      setUrl(resultUrl);

      console.log(resultUrl);

      if (resultUrl == "https://alfinaltwo.reforce.com.pk/api/all-products?c_id=&sc_id=") {
        resultUrl = "https://alfinaltwo.reforce.com.pk/alnoor/all-products";
      }

      const getallproducts = async () => {

        const res = await axios.get(resultUrl);
        // console.log(url);
        if (res.status === 200) {
        //console.log(res.data.data[0])
        console.log('Products Data:');
          console.log(res.data.data);
          setallproducts(res.data.data);
          loadMore();
        }

      };

      getallproducts();

    });
    
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  const filter = searchParams.get('filter');

  const [allproducts, setallproducts] = useState([]);

  const [allcategories, setallcategories] = useState([]);

  const [url, setUrl] = useState("https://alfinaltwo.reforce.com.pk/alnoor/all-products");

  useEffect(() => {
    
    getallproducts();
    getallcategories();
    jQueryFilterTags();
    filterLink();
    
  }, []);

  const getallproducts = async () => {

    const res = await axios.get(url);
    // console.log(url);
    if (res.status === 200) {
    //console.log(res.data.data[0])
    console.log('Products Data:');
      console.log(res.data.data)
      setallproducts(res.data.data)
    }

  };

  const getallcategories = async () => {

    const res = await axios.get(`https://alfinaltwo.reforce.com.pk/alnoor/all-categories`);
    if (res.status === 200) {
    console.log(res.data.data[0])
    console.log('Categories Data:');
    console.log(res.data.data);
      //console.log(res)
      setallcategories(res.data.data)
    }

  };

  const jQueryFilterTags = () => {
      
      
    $("#filter").keyup(function () {
      
      //$(".tags input:checkbox").prop('checked', false);
      $(".sub-filters").fadeOut();
        // Retrieve the input field text and reset the count to zero
        var filter = $(this).val(),
            count = 0;

        // Loop through the comment list
        $("li").each(function () {

            // If the list item does not contain the text phrase fade it out
            if ($(this).text().search(new RegExp(filter, "i")) < 0) {
                $(this).fadeOut(0).addClass('hidden');
                $(this).attr('style', 'display:none !important');

                // Show the list item if the phrase matches and increase the count by 1
            } else {
              $(this).show().removeClass('hidden');  
              //count++;
            }
        });

        // Update the count
        // var numberItems = count;
        // $("#filter-count").text("Number of Comments = " + count);
    });


    $(".h-gloss").on('click', function () {

        if ($(this).find('input:checkbox:checked').length > 0) {
            $(".h-gloss-sf").find('input[type="checkbox"]').prop("checked", false);
            $(".h-gloss-sf").fadeIn();
        } else {
            $(this).prop("checked", false);
            $(".h-gloss-sf").fadeOut();
        }

    });

    $(".tactile").on('click', function () {

      if ($(this).find('input:checkbox:checked').length > 0) {
          $(".tactile-sf").find('input[type="checkbox"]').prop("checked", false);
          $(".tactile-sf").fadeIn();
      } else {
          $(this).prop("checked", false);
          $(".tactile-sf").fadeOut();
      }

    });

  }

  const filterLink = async () => {

    if( filter === 'select' ) {

      $(".select").find('input[type="checkbox"]').click();

    }

    if( filter === 'tactile' ) {

      $(".tactile").find('input[type="checkbox"]').click();

    }

    if( filter === 'scintilla' ) {

      $(".scintilla").find('input[type="checkbox"]').click();

    }


  };

  return (
      
  <div>
      

  <div style={{boxShadow:"-7px 7px 45px 0px rgba(0, 0, 0, 0.1)"}}>
        <Header />
  </div>



    <div>
    <video height="100%" width="100%" loop autoPlay muted>    
      <source src="/videos/scintilla/video-banner.mp4" type="video/mp4" />
    </video>
      {/* Scintilla */}
    </div>

    <div className="container text-center mt-5">

      <p style={{fontSize: "20px", color: "#000", paddingLeft: "18%", paddingRight: "18%"}}>Scintilla, our latest creation of premium unicolors that stand out with their scintillating finish
      using the latest lamination technology, creates a dynamic surface with an eccentric appeal.</p>

      <p style={{fontSize: "20px", color: "#000", paddingLeft: "18%", paddingRight: "18%"}}>The product is defined by a unique uniform metallic surface, when combined with Al-Noor Lasani’s
      Super Gloss and High Gloss finish, achieves high-quality accents for any living space.</p>

      <div className="mt-5">

      <a style={{color: "#000", cursor: "pointer"}} href="https://google.com" target="_blank">
        <label>
        <img style={{border: "2px solid #30302f", borderRadius: "35px", cursor: "pointer"}} className="mr-5 ml-5" height="150" width="145" src="/images/star.png"></img><br></br>
        Pearlescent/<br></br>Fluorescent effect
        </label>
      </a>

      <a style={{color: "#000", cursor: "pointer"}} href="https://google.com" target="_blank">
        <label>
        <img style={{border: "2px solid #30302f", borderRadius: "35px", cursor: "pointer"}} className="mr-5 ml-5" height="150" width="145" src="/images/scintilla-sparkle.gif"></img><br></br>
        Sparkling<br></br>Surface
        </label>
      </a>

      </div>

    </div>

    <div className="d-none" style={{ width: "100%", marginBottom: "20px", marginTop:"50px" }}>
      <p style={{fontSize:"22px",fontWeight:"bold",fontFamily:"AvenirRoman",color:"#4b4b4b",textAlign:"center"}}>
        The Decors Library
      </p>
    </div>
        
    <div className="d-none" style={{width: "100%", marginLeft: "auto", marginRight: "auto", marginBottom: "0px"}}>


      <div style={{width: "50%", marginLeft: "auto", marginRight: "auto", marginBottom: "0px"}}>
      <form id="live-search" action="" class="styled" method="post">

      <div>
        <input type="text" id="filter" placeholder="Search by code....." style={{width: "100%",height: "38px",border: "2px solid black", borderRadius: "12px", padding: "15px", paddingTop: "14.5px"}} />
      </div>

      <div className="d-flex" style={{width: "100%", marginLeft: "auto", marginRight: "auto", marginTop: "15px", paddingLeft: "1em"}}>

        <div class="tags" style={{marginLeft: "auto", marginRight: "auto"}}>

            <div class="form-check form-check-inline">
              <input  class="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox1" rel="16" value="option1" />
              <label class="form-check-label" for="inlineCheckbox1">S Gloss</label>
            </div>

            <div class="form-check form-check-inline tactile">
              <input  class="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox2" rel="17" value="option2" />
              <label class="form-check-label" for="inlineCheckbox2">Tactile</label>
            </div>

            <div class="form-check form-check-inline scintilla">
              <input  class="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox21" rel="23" value="option2" />
              <label class="form-check-label" for="inlineCheckbox21">Scintilla</label>
            </div>

            <div class="form-check form-check-inline h-gloss">
              <input  class="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox3" rel="18" value="option1" />
              <label class="form-check-label" for="inlineCheckbox3">High Gloss</label>
            </div>

            <div class="form-check form-check-inline">
              <input  class="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox4" rel="19" value="option2" />
              <label class="form-check-label" for="inlineCheckbox4">Natural Matte</label>
            </div>

            <div class="form-check form-check-inline select">
              <input  class="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox5" rel="20" value="option1" />
              <label class="form-check-label" for="inlineCheckbox5">Select</label>
            </div>

            <div class="form-check form-check-inline">
              <input  class="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox6" rel="21" value="option2" />
              <label class="form-check-label" for="inlineCheckbox6">Wood Grain</label>
            </div>

            <div class="form-check form-check-inline">
              <input  class="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox7" rel="22" value="option1" />
              <label class="form-check-label" for="inlineCheckbox7">Solid</label>
            </div>

            <center>
            <div class="d-block text-center mt-2" style={{width: "fit-content"}}>
            <center>
              
              <div class="sub-filters tactile-sf mt-2" style={{display: "none"}}>
                
                <div class="d-inline mr-2 ml-2">
                  <div class="form-check form-check-inline">
                    <input  class="form-check-input mt-1 s-cat" type="checkbox" id="4" rel="4" value="option1" />
                    <label class="form-check-label" for="4">Natural Veneer</label>
                  </div>
                </div>

                <div class="d-inline mr-2 ml-2">
                  <div class="form-check form-check-inline">
                    <input  class="form-check-input mt-1 s-cat" type="checkbox" id="6" rel="6" value="option1" />
                    <label class="form-check-label" for="6">Brushed Oak</label>
                  </div>
                </div>

                <div class="d-inline mr-2 ml-2">
                  <div class="form-check form-check-inline">
                    <input  class="form-check-input mt-1 s-cat" type="checkbox" id="35" rel="35" value="option1" />
                    <label class="form-check-label" for="35">Vintage</label>
                  </div>
                </div>

                <div class="d-inline mr-2 ml-2">
                  <div class="form-check form-check-inline">
                    <input  class="form-check-input mt-1 s-cat" type="checkbox" id="7" rel="7" value="option1" />
                    <label class="form-check-label" for="7">Calcite</label>
                  </div>
                </div>

                <div class="d-inline mr-2 ml-2">
                  <div class="form-check form-check-inline">
                    <input  class="form-check-input mt-1 s-cat" type="checkbox" id="9" rel="9" value="option1" />
                    <label class="form-check-label" for="9">Textile</label>
                  </div>
                </div>

              </div>

              <div class="sub-filters h-gloss-sf mt-2" style={{display: "none"}}>

                <div class="d-inline mr-2 ml-2">
                  <div class="form-check form-check-inline">
                    <input  class="form-check-input mt-1 s-cat" type="checkbox" id="2" rel="3" value="option1" />
                    <label class="form-check-label" for="2">Richard Sim</label>
                  </div>
                </div>

              <div class="d-inline mr-2 ml-2">
                <div class="form-check form-check-inline">
                  <input  class="form-check-input mt-1 s-cat" type="checkbox" id="3" rel="4" value="option1" />
                  <label class="form-check-label" for="3">Kato Rose</label>
                </div>
              </div>
              
              </div>

          </center>
          </div>
          </center>

        </div>

      </div>

      </form>
      </div>
              
    </div>


    <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>

      <div className="row pt-3 justify-content-center dlist-container tabNav" style={{width: "100%,", marginLeft: "auto", marginRight: "auto", marginBottom: "80px"}}>

        <ul className="decorList text-center">

          {Object.keys(allproducts).map((i, j) =>  (

            <li>

              <a target="_blank" href={"/decorsdetail/"+allproducts[i].product_slug} className="dlist-item col-xs-3 col-sm-3 col-md-3 col-lg-3 " style={{ width: "331px", height: "388px"}}>

                <div style={{width: "331px", overflow: "hidden", height: "388px", boxShadow: "2px 5px 8px 4px rgba(0, 0, 0, 0.4)", borderRadius: "40px"}} >
                  <div className="decimg">
                    <img width="331px" height="388px" src= {allproducts[i].product_image} alt="Alnoor" objectFit="cover"></img>
                  </div>
                </div>

                <div style={{position: "absolute", marginTop: "-72px", marginLeft: "0px", width: "331px", height: "72px", backgroundColor: "rgba(85,77,68,0.7)", borderBottomLeftRadius: "40px", borderBottomRightRadius: "40px", zIndex: 25}}>
                  
                  <p className="decp">
                    {allproducts[i].product_name}
                  </p>

                </div>
            
              </a>

              </li>

          ))}

        </ul>

        <div className="text-center mt-4 more" style={{cursor: "pointer"}}>
          <img src="/images/scintilla-mouse.png" />
        </div>

      </div>

    </div>



  <Footer/>
        
  </div>

  );
};

export default Decorslist;
