import React , { useEffect } from "react";
import { FaApple } from "react-icons/fa";

import { DiAndroid } from "react-icons/di";
import { motion } from "framer-motion";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-creative";
import "swiper/css/pagination";

import Bookslider from "../components/bookslider";
import Homediaries from "../components/Homediaries";
import Homedi from "../components/homdi";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Cslider from "../components/curatedslider";
const Homepage = () => {

  useEffect(() => {
   
    tdl();
    
  }, []);

const tdl = () => {
  (() => {
    const h1El = document.getElementById('tdl');
    const letters = h1El.textContent.split('');
    h1El.innerHTML = letters.map((l, i) => `<p style="--i: ${i}; --j: ${letters.length -1 - i}">${l == ' ' ? '&nbsp;': l}</p>`).join('');
    
    h1El.addEventListener('mouseenter', () => {
      h1El.classList.add('flip-in');
      h1El.classList.remove('flip-out');
    });
    
    h1El.addEventListener('mouseleave', () => {
      h1El.classList.remove('flip-in');
      h1El.classList.add('flip-out');
    });
  })()
}

  const spans = document.querySelectorAll('.word span');

  spans.forEach((span, idx) => {
    span.addEventListener('click', (e) => {
      e.target.classList.add('active');
    });
    span.addEventListener('animationend', (e) => {
      e.target.classList.remove('active');
    });
    
    // Initial animation
    setTimeout(() => {
      span.classList.add('active');
    }, 750 * (idx+1))
  });
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <div>
      <div
        style={{
          width: "100%",
          backgroundColor: "#000",
          position: "sticky",
          zIndex: 90,
          top: "0px",
        }}
      >
        <Header />
      </div>
      <div>
        <video height="100%" width="100%" loop autoPlay muted>
          <source src="/videos/homepage/banners.mp4" type="video/mp4" />
        
        </video>
      </div>
      
      <div
        style={{
          width: "100%",
        }}
      >
        <div className="Trenmaindiv">
          <h5 className="Trenheading">Trending</h5>
          <a href="/trending-details"><motion.img
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.2 }}
            whileTap={{ scale: 0.99, borderRadius: "1%" }}
            className="img-fluid"
            width="100%"
            height="100%"
            src="/images/trending.png"
            alt="Alnoor"
              
            objectFit="cover"
          /></a>
        </div>
      </div>

      <div className="mbdiv">

        <div className="brandsdiv text-center">
        <div className="brandsdiv-1">
         <div className="brandsdiv-2">
          <img
            className="Trenimg img-fluid"
            
            src="/images/home-4.png"
            alt="Alnoor"
            objectFit="cover"
          />
          <img
            className="Trenimg img-fluid"
            
            src="/images/home-5.png"
            alt="Alnoor"
            objectFit="cover"
          />
          <img
            className="Trenimg img-fluid"
            
            src="/images/home-1.png"
            alt="Alnoor"
            objectFit="cover"
          />
          <img
            className="Trenimg img-fluid"
            
            src="/images/home-3.png"
            alt="Alnoor"
            objectFit="cover"
          />
          <img
            className="Trenimg img-fluid"
            
            src="/images/home-2.png"
            alt="Alnoor"
            objectFit="cover"
          />
          </div>
          </div>
        </div>
       
      </div>

      {/* Section 3 */}

      <div className="boutmd">
        <div>
          <p className="boutp">
            With a focus on innovation and high-precision engineering, Al Noor
            Lasani is the market leader and pioneer of decorative laminate
            surfaces in Pakistan.
          </p>
          <p className="boutp">
            From home to commercial décor, from kitchens to furniture, our wide
            range of textures and finishes, and large colour pallet has made us
            the premier choice for design and building professionals for over 30
            years.
          </p>
          <p className="boutp">
            We provide design solutions to adapt to your space, and your
            individuality. From contemporary mirror-finish-glossy, earthy matte,
            to artistic tactile, our surfaces bring your ideas to life. Our
            proprietary S Gloss, Tactile, Natural Matte, High gloss and Select
            series products are manufactured and distributed under the Al-Noor
            Lasani umbrella brand.
          </p>
        </div>
      </div>

    
      {/* <div className="select-sec" style={{ width: "100%", marginTop: "43px" }}>
        <div className="Selectmd">
          <motion.img
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.5 }}
            whileTap={{ scale: 1.0 }}
            width="236px"
            height="108px"
            src="/images/select.png"
            alt="Alnoor"
            objectFit="cover"
          />
          <p className="text-center Selectmd-text">Select from Al-Noor Lasani is the latest range of carefully curated avant-garde design trends from Europe. Harmonized with our exclusive textures, the Select range puts emphasis on innovative features such as striking growth lines and natural figures with attractive 3D effects reproducing authentic finishes.</p>
        </div> 
        <img
          className="img-fluid"
          src="/images/selbg.jpg"
          alt="Alnoor"
          objectFit="cover"
        />
      </div> */}

      <div class="select-center mt-5" style={{background: "url('/images/select-bannernew.jpg')"}}>
        <a href="/decors/?filter=select" target="_blank"><img src="https://alfinaltwo.reforce.com.pk/images/select.png" /></a>
        <p>Select from Al-Noor Lasani is the latest range of carefully curated avant-garde design trends from Europe. Harmonized with our exclusive textures, the Select range puts emphasis on innovative features such as striking growth lines and natural figures with attractive 3D effects reproducing authentic finishes.</p>
      </div>

      {/* Section 5 */}

      <div className="sec5md">
        <div className="moodmd">
          <div
            style={{
              width: "80%",
              margin: "auto",
            }}
          >
            <h6 className="moodheading">Mood Board</h6>
          </div>
          <div className="moodimgd">
            <img
              width="100%"
              src="/images/tablet.png"
              alt="Alnoor"
              objectFit="contain"
            />
          </div>

          <div className="appiconmd">
         
            <FaApple
              className="appicon"
              style={{
                marginLeft: "25px",
              }}
            />
            <DiAndroid
              className="appicon"
              style={{
                marginLeft: "20px",
              }}
            />
          </div>
        </div>

      

        <motion.div className="curbc">
          <motion.div
            className="curbdotc"
            animate={{
              y: [
                0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 20, 18, 16, 14, 12, 10,
                8, 6, 4, 2, 0,
              ],
              x: [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              ],
            }}
            transition={{ duration: 2.5, yoyo: Infinity }}
          ></motion.div>
        </motion.div>

        <div className="booksliderdiv">
          <Bookslider />
        </div>
      </div>

      {/* Section 6 */}

      <motion.div className="hdmdeone">
        <motion.div
          className="hdmdetwo"
          animate={{
            y: [
              0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 20, 18, 16, 14, 12, 10, 8,
              6, 4, 2, 0,
            ],
            x: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
          }}
          transition={{ duration: 2.5, yoyo: Infinity }}
        ></motion.div>
      </motion.div>

      <div style={{ width: "100%", marginTop: "94px", zIndex: 80 }}>
        <h6 className="homediariesh">Design Diaries</h6>

        {/* <Homediaries /> */}
        <Homedi />
      </div>

      {/* Section 7 */}

      <div className="tactmd">
        <div className="tactimgdiv">
          <img
            width="100%"
            height="100%"
            src="/images/tactilelog.png"
            alt="Alnoor"
            objectFit="contain"
          />
        </div>
        <div className="tactpdiv">
          {" "}
          <p className="tactp">
            Tactile is part of Al Noor's commitment to push boundaries in decor
            trends through innovative design, textures.
          </p>
        </div>
        <div className="tactbandiv">
          {" "}
          <a href="/decors/?filter=tactile" target="_blank">
          <img
            className="tactbanimg"
            width="100%"
            height="100%"
            src="/images/tacban.png"
            alt="Alnoor"
            objectFit="cover"
          />
          </a>
        </div>
      </div>

      {/* Section 8 */}
     

      <a href="/decors">
        <motion.div
          className="decolibdiv"
        >
         
          <div className="texthov" onMouseOver={tdl}>

            <div id="tdl">The Decors Library</div>

            <span  className="decolibhone1" >T</span>
            <span  className="decolibhone2" >h</span>
            <span className="decolibhone3" >e</span>

            <span className="decolibhone4">D</span>
            <span className="decolibhone5">e</span>
            <span className="decolibhone6">c</span>
            <span className="decolibhone7">o</span>
            <span className="decolibhone8">r</span>
            <span className="decolibhone9">s</span>

            <span className="decolibhone10">L</span>
            <span className="decolibhone11">i</span>
            <span className="decolibhone12">b</span>
            <span className="decolibhone13">r</span>
            <span className="decolibhone14">a</span>
            <span className="decolibhone15">r</span>
            <span className="decolibhone16">y</span>
            <p className="decolibhtwo"> A spectrum of hundreds of decors</p>
          </div>

         
          <img
            className="decolibimg"
            src="/images/decorslib.png"
            alt="Alnoor"
          />
        </motion.div>
      </a>

      {/* Section 9 */}

      <div className="Curatedmd" style={{ width: "100%" }}>
        <h6 className="Curatedheading">Curated Collection</h6>
        <div className="desktop-view">
        <Cslider />
        </div>
      </div>













<div id="demo" class="carousel slide mobile-view" data-ride="carousel">

<ul class="carousel-indicators d-none">
  <li data-target="#demo" data-slide-to="0" class=""></li>
  <li data-target="#demo" data-slide-to="1" class="active"></li>
  <li data-target="#demo" data-slide-to="2"></li>
</ul>

<div class="container carousel-inner no-padding">

  <div class="carousel-item active carousel-item-left">

    <div class="col-sm-12">
    <img src="/images/c_1.jpg"></img>
    </div>

  </div>

  <div class="carousel-item carousel-item-next carousel-item-left">

    <div class="col-sm-12">
    <img src="/images/c_2.png"></img>
    </div>

  </div>

  <div class="carousel-item carousel-item-next carousel-item-left">

    <div class="col-sm-12">
    <img src="/images/c_3.png"></img>
    </div>

  </div>

  <div class="carousel-item carousel-item-next carousel-item-left">

    <div class="col-sm-12">
    <img src="/images/c_4.png"></img>
    </div>

  </div>

  <div class="carousel-item carousel-item-next carousel-item-left">

    <div class="col-sm-12">
    <img src="/images/c_5.png"></img>
    </div>

  </div>

  <div class="carousel-item carousel-item-next carousel-item-left">

    <div class="col-sm-12">
    <img src="/images/c_6.png"></img>
    </div>

  </div>

  <div class="carousel-item carousel-item-next carousel-item-left">

    <div class="col-sm-12">
    <img src="/images/c_7.png"></img>
    </div>

  </div>

  <div class="carousel-item carousel-item-next carousel-item-left">

    <div class="col-sm-12">
    <img src="/images/c_8.png"></img>
    </div>

  </div>

  <div class="carousel-item carousel-item-next carousel-item-left">

    <div class="col-sm-12">
    <img src="/images/c_9.png"></img>
    </div>

  </div>

  <div class="carousel-item carousel-item-next carousel-item-left">

    <div class="col-sm-12">
    <img src="/images/c_10.png"></img>
    </div>

  </div>

  <div class="carousel-item carousel-item-next carousel-item-left">

    <div class="col-sm-12">
    <img src="/images/c_11.png"></img>
    </div>

  </div>

</div>

<a class="carousel-control-prev" href="#demo" data-slide="prev">
  <span class="carousel-control-prev-icon"></span>
</a>
<a class="carousel-control-next" href="#demo" data-slide="next">
  <span class="carousel-control-next-icon"></span>
</a>

</div>










    <Footer/>
    </div>
  );
};

export default Homepage;
