import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/navigation";

// import required modules
import { EffectCreative, Navigation } from "swiper";

const bookslider = () => {
  return (
    <Swiper
      grabCursor={true}
      effect={"creative"}
      creativeEffect={{
        prev: {
          shadow: true,
          translate: [0, 0, -400],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      }}
      modules={[EffectCreative, Navigation]}
      navigation
      className="mySwiper"
    >
      <SwiperSlide className="bookmd">
       
        <div
         className="swiperbookh"
        >
         Design Collections
        </div>
        {/* <input className="swiperbookinp" value="View" type="button"></input> */}
        <img className="swiperbookimg" src="/images/1a.png" />
      </SwiperSlide>
      <SwiperSlide>
        <div
         className="swiperbookh"
        >
          Trends 2020
        </div>
        {/* <input className="swiperbookinp" value="View" type="button"></input>{" "} */}
        <img className="swiperbookimg" src="/images/2a.png" />
      </SwiperSlide>
      <SwiperSlide>
      <div
      className="swiperbookh"
         
        >
          Trends 2019
        </div>
        {/* <input
          className="swiperbookinp"
          value="View"
          type="button"
        ></input>{" "} */}
        <img className="swiperbookimg" src="/images/3a.png" />
      </SwiperSlide>
    </Swiper>
  );
};

export default bookslider;
