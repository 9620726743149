import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

import $ from "jquery";

const style1 = { display: "none" };

const imageZoom = async () => {
  // $(".lb-btn").on("click", function () {
  //   var src = $(this).attr("src");
  //   $(".product_image").attr("src", src);
  //   $(".centered-div").fadeIn();
  // });

  // $(".close").on("click", function () {
  //   $(".centered-div").fadeOut();
  // });

  $(".fa-angle-left").on("click", function () {
    $(".swiper-button-prev").click();
  });

  $(".fa-angle-right").on("click", function () {
    $(".swiper-button-next").click();
  });

  $(".swiper-button-next, .swiper-button-prev").attr("style", "display:none");

  $("body").keyup(function (e) {
    var code = e.keyCode || e.which; //Find the key pressed

    if (code == 39) {
      $(".fa-angle-right").click();
    }
    if (code == 37) {
      $(".fa-angle-left").click();
    }
  });

  $(".dbl-sub-sec").on("mouseenter", function () {
    $(this).find(".decor-tag-link").fadeIn();
  });

  $(".dbl-sub-sec").on("mouseleave", function () {
    $(this).find(".decor-tag-link").fadeOut();
  });
};

const Homediaries = () => {
  useEffect(() => {
    imageZoom();
  }, []);

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={20}
      modules={[Navigation]}
      navigation={true}
      className="mySwiper nexthd prvhd"
    >
      <SwiperSlide className="d-flex align-items-center justify-content-center mt-4">
        <center>
          <div className="container-fluid dbl-2 mt-3 mb-3">
            <div className="row">
              <div className="col-12 col-md-12 pl-2 pr-2">
                <div className="h-100">
                  <img className="mt-1 w-100 h-100" src="/images/2019/1.png" />
                </div>
              </div>
            </div>
          </div>
        </center>
      </SwiperSlide>

      <SwiperSlide className="d-flex align-items-center justify-content-center mt-4">
        <div className="container-fluid dbl-2 mt-3 mb-3">
          <div className="row">
            <div className="col-12 col-md-12 pl-2 pr-2">
              <div className="h-100">
                <img className="mt-1 w-100 h-100" src="/images/2019/2.png" />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: 1003</p>
            <div className="col-7 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <a href="/decorsdetail/1003">
                  <img
                    className="lb-btn mt-1 mb-2 w-100 h-100"
                    src="/images/2019/1003/10031.jpg"
                  />
                </a>
              </div>
            </div>
            <div className="col-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/1003">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2019/1003/1003.jpg"
                  ></img>
                </a>{" "}
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row">
            <p className="m-0">Decor Code: 1005</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
            <a href="/decorsdetail/1005">

              <img
                className="lb-btn mt-1 mb-1 w-100 h-100"
                src="images/2019/1005/11.jpg"
              ></img></a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/1005">

                    <img
                      className="w-100 h-100"
                      src="images/2019/1005/1.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/1005">

                    <img
                      className="w-100 h-100"
                      src="images/2019/1005/2.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row">
            <p className="m-0">Decor Code: 1006</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
            <a href="/decorsdetail/1006">

              <img
                className="lb-btn mt-1 mb-1 w-100 h-100"
                src="images/2019/1006/11.jpg"
              ></img></a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/1006">

                    <img
                      className="w-100 h-100"
                      src="images/2019/1006/1.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/1006">

                    <img
                      className="w-100 h-100"
                      src="images/2019/1006/2.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row">
            <p className="m-0">Decor Code: 1007</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
            <a href="/decorsdetail/1007">

              <img
                className="lb-btn mt-1 mb-1 w-100 h-100"
                src="images/2019/1007/11.jpg"
              ></img></a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/1006">

                    <img
                      className="w-100 h-100"
                      src="images/2019/1007/1.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/1006">

                    <img
                      className="w-100 h-100"
                      src="images/2019/1007/2.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row">
            <p className="m-0">Decor Code: 1009</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
            <a href="/decorsdetail/1009">

              <img
                className="lb-btn mt-1 mb-1 w-100 h-100"
                src="images/2019/1009/11.jpg"
              ></img></a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/1009">

                    <img
                      className="w-100 h-100"
                      src="images/2019/1009/1.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/1009">

                    <img
                      className="w-100 h-100"
                      src="images/2019/1009/2.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: 1050</p>
            <div className="col-7 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
            <a href="/decorsdetail/1050">

                <img
                  className="lb-btn mt-1 mb-2 w-100 h-100"
                  src="/images/2019/1050/8.1.png"
                /></a>
              </div>
            </div>
            <div className="col-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
            <a href="/decorsdetail/1050">

                <img
                  className="mt-1 w-100 h-100"
                  src="/images/2019/1050/1050.jpg"
                ></img></a>
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row">
            <p className="m-0">Decor Code: 1051</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
            <a href="/decorsdetail/1051">

              <img
                className="lb-btn mt-1 mb-1 w-100 h-100"
                src="/images/2019/1051/11.jpg"
              ></img></a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/1051">

                    <img
                      className="w-100 h-100"
                      src="/images/2019/1051/2.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/1051">

                    <img
                      className="w-100 h-100"
                      src="/images/2019/1051/1.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row">
            <p className="m-0">Decor Code: 1052</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
            <a href="/decorsdetail/1052">

              <img
                className="lb-btn mt-1 mb-1 w-100 h-100"
                src="/images/2019/1052/11.jpg"
              ></img></a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/1052">

                    <img
                      className="w-100 h-100"
                      src="/images/2019/1052/2.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/1052">

                    <img
                      className="w-100 h-100"
                      src="/images/2019/1052/1.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: 1053</p>
            <div className="col-7 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
            <a href="/decorsdetail/1053">

                <img
                  className="lb-btn mt-1 mb-2 w-100 h-100"
                  src="/images/2019/1053/1.jpg"
                /></a>
              </div>
            </div>
            <div className="col-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
            <a href="/decorsdetail/1053">

                <img
                  className="mt-1 w-100 h-100"
                  src="/images/2019/1053/1053.jpg"
                ></img></a>
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: 1054</p>
            <div className="col-7 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
            <a href="/decorsdetail/1054">

                <img
                  className="lb-btn mt-1 mb-2 w-100 h-100"
                  src="/images/2019/1054/11.jpg"
                /></a>
              </div>
            </div>
            <div className="col-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
            <a href="/decorsdetail/1054">

                <img
                  className="mt-1 w-100 h-100"
                  src="/images/2019/1054/1.jpg"
                ></img></a>
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row">
            <p className="m-0">Decor Code: 1057</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
            <a href="/decorsdetail/1057">

              <img
                className="lb-btn mt-1 mb-1 w-100 h-100"
                src="/images/2019/1057/11.jpg"
              ></img></a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/1057">

                    <img
                      className="w-100 h-100"
                      src="/images/2019/1057/2.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/1057">

                    <img
                      className="w-100 h-100"
                      src="/images/2019/1057/1.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row">
            <p className="m-0">Decor Code: 1058</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
            <a href="/decorsdetail/1058">

              <img
                className="lb-btn mt-1 mb-1 w-100 h-100"
                src="/images/2019/1058/11.jpg"
              ></img></a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/1058">

                    <img
                      className="w-100 h-100"
                      src="/images/2019/1058/2.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/1058">

                    <img
                      className="w-100 h-100"
                      src="/images/2019/1058/1.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: 4070</p>
            <div className="col-7 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
            <a href="/decorsdetail/4070">

                <img
                  className="lb-btn mt-1 mb-2 w-100 h-100"
                  src="/images/2019/4070/11.jpg"
                /></a>
              </div>
            </div>
            <div className="col-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
            <a href="/decorsdetail/4070">

                <img
                  className="mt-1 w-100 h-100"
                  src="/images/2019/4070/1.jpg"
                ></img></a>
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row">
            <p className="m-0">Decor Code: 4071</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
            <a href="/decorsdetail/4071">

              <img
                className="lb-btn mt-1 mb-1 w-100 h-100"
                src="/images/2019/4071/11.jpg"
              ></img></a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/4071">

                    <img
                      className="w-100 h-100"
                      src="/images/2019/4071/2.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/4071">

                    <img
                      className="w-100 h-100"
                      src="/images/2019/4071/1.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row">
            <p className="m-0">Decor Code: 2027</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
            <a href="/decorsdetail/2027-lime-yellow">

              <img
                className="lb-btn mt-1 mb-1 w-100 h-100"
                src="/images/2019/2027/11.jpg"
              ></img></a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/2027-lime-yellow">

                    <img
                      className="w-100 h-100"
                      src="/images/2019/2027/2.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
            <a href="/decorsdetail/2027-lime-yellow">

                    <img
                      className="w-100 h-100"
                      src="/images/2019/2027/1.jpg"
                    ></img></a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <div className="text-center mt-3 catalog">
        <p>
          <i class="fa-solid fa-angle-left"></i>

          <i class="fa-solid fa-angle-right"></i>
        </p>
      </div>
    </Swiper>
  );
};

export default Homediaries;
