import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

import ReactBootstrap from "react-bootstrap";
const Index = () => {
  return (
    <div >
      {/* actual footer code  */}

      {/* green patta  */}
      <div className="topdiv"
      />

      <div className="footermd">
        <div className="footer-links-container text-center">

          <a href="/our-design-studios" className="footer-links">Our Design Studios</a>

          <a href="/technical-specifications" className="footer-links">Technical Specifications</a>
          <a href="/about" className="footer-links">About Us</a>
          <a href="/our-dealership" className="footer-links">Our Dealership</a>
          <a href="/contact-us" className="footer-links">Contact Us</a>
        
        </div>
      </div>
    </div>
  );
}
export default Index;
