import React , { useEffect, useLayoutEffect, useState } from "react";
import { motion } from "framer-motion";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from 'axios';
import { Link, useParams } from "react-router-dom";

import $ from 'jquery';

const style1 = {display: "none"};

const Dlist = () => {
  
  let { product_slug } = useParams();


  const [productdetails, setproductdetails] = useState([]);

/*

  useEffect(() => {
    const LoadExternalScript = () => {
      const externalScript = document.createElement("script");
      externalScript.id = "external";
      externalScript.async = true;
      externalScript.type = "text/javascript";
      externalScript.setAttribute("crossorigin", "anonymous");
      document.body.appendChild(externalScript);
      externalScript.src = `https://cdnjs.cloudflare.com/ajax/libs/jquery-zoom/1.7.20/jquery.zoom.min.js`;
    };
    LoadExternalScript();
  }, []);

*/

//You can use useEffect hook like this:

/*

useEffect(() => {
let ignore = false;

if (!ignore) someFunction();
return () => { ignore = true; }
},[]);

*/

useEffect(() => {

   getoneprod();

}, []);


  useEffect(() => {
   
    imageChange();
    
  }, []);

  useEffect(() => {
   
    topImgthumb();
    
  }, []);

  const topImgthumb = async () => {

    // $("#cct-1").on('click', function(){
    //   $('.decorbrandlogosbody .m-product img:first-child').click();
    // });

    // $("#cct-2").on('click', function(){
    //   $('.decorbrandlogosbody .r-product img:first-child').click();
    // });

    // // $("#cct-1").on('click', function(){
    // //   $('.decorbrandlogosbody img:first-child').click();
    // // });

    // // $("#cct-2").on('click', function(){
    // //   $('.decorbrandlogosbody img:nth-child(2)').click();
    // // });

    // $("#cct-3").on('click', function(){
    //   $('.decorbrandlogosbody img:nth-child(3)').click();
    // });

    // $("#cct-4").on('click', function(){
    //   $('.decorbrandlogosbody img:nth-child(4)').click();
    // });

    // $("#cct-5").on('click', function(){
    //   $('.decorbrandlogosbody img:nth-child(5)').click();
    // });

    // $("#cct-6").on('click', function(){
    //   $('.decorbrandlogosbody img:nth-child(6)').click();
    // });

    // $("#cct-7").on('click', function(){
    //   $('.decorbrandlogosbody img:nth-child(7)').click();
    // });

    // $("#cct-8").on('click', function(){
    //   $('.decorbrandlogosbody img:nth-child(8)').click();
    // });

    // $("#cct-9").on('click', function(){
    //   $('.decorbrandlogosbody img:nth-child(9)').click();
    // });

    // $("#cct-10").on('click', function(){
    //   $('.decorbrandlogosbody img:nth-child(10)').click();
    // });

    $('.cc-popup img').on('click', function(){

      var img = $(this).attr('src');

      $(this).removeClass('active-slide');
      $(this).removeClass('previous');
      $(this).removeClass('next');
    
      $(this).prev().addClass('previous');
      $(this).addClass('active-slide');
      $(this).next().addClass('next');

      $('.decimg img').attr('src', img);
      $('#product_image').attr('src', img);
      $('.zoomImg').attr('src', img);

    });
    
    $('.next').on('click', function(){

      var img = $(this).attr('src');

      $('.cc-popup img').removeClass('active-slide');
      $('.cc-popup img').removeClass('previous');
      $('.cc-popup img').removeClass('next');
    
      $(this).prev().addClass('previous');
      $(this).addClass('active-slide');
      $(this).next().addClass('next');
      
      $('.decimg img').attr('src', img);
      $('#product_image').attr('src', img);
      $('.zoomImg').attr('src', img);

    });

    $('.previous').on('click', function(){

      var img = $(this).attr('src');

      $('.cc-popup img').removeClass('active-slide');
      $('.cc-popup img').removeClass('previous');
      $('.cc-popup img').removeClass('next');
    
      $(this).prev().addClass('previous');
      $(this).addClass('active-slide');
      $(this).next().addClass('next');
      
      $('.decimg img').attr('src', img);
      $('#product_image').attr('src', img);
      $('.zoomImg').attr('src', img);

    });

  }

  useEffect(() => {
   
    topImgthumb2();
    subImgFix();
    
  }, []);

  const subImgFix = async () => {

    $('.decorsubimg div').on('mousemove', function(){

      // $(this).removeClass('active-slide2');
      // $(this).addClass('active-slide2');

      $(this).removeClass('previous2');
      $(this).removeClass('next2');
    
      $(this).prev().addClass('previous2');
    
      if($(this).prev().hasClass('previous2')){
        $(this).addClass('next2');
      }
      else{
        $(this).addClass('previous2');
      }

      $(this).next().addClass('next2');
      
    });

  }

  const topImgthumb2 = async () => {

    $('.decorsubimg img').on('click', function(){

      var img = $(this).attr('src');

      $(this).removeClass('active-slide2');
      $(this).removeClass('previous2');
      $(this).removeClass('next2');
    
      $(this).prev().addClass('previous2');
      $(this).addClass('active-slide2');
      $(this).next().addClass('next2');

      //$('.decimg img').attr('src', img);
      $('.product_image').attr('src', img);
      //$('.zoomImg').attr('src', img);

    });
    
    $('.next2').on('click', function(){

      var img = $(this).attr('data-img');

      $('.decorsubimg div').removeClass('active-slide2');
      $('.decorsubimg div').removeClass('previous2');
      $('.decorsubimg div').removeClass('next2');
    
      $(this).prev().addClass('previous2');
      $(this).addClass('active-slide2');
      $(this).next().addClass('next2');
      
      //$('.decimg img').attr('src', img);
      $('.product_image').attr('src', img);
      //$('.zoomImg').attr('src', img);

    });

    $('.previous2').on('click', function(){

      var img = $(this).attr('data-img');

      $('.decorsubimg div').removeClass('active-slide2');
      $('.decorsubimg div').removeClass('previous2');
      $('.decorsubimg div').removeClass('next2');
    
      $(this).prev().addClass('previous2');
      $(this).addClass('active-slide2');
      $(this).next().addClass('next2');
      
      //$('.decimg img').attr('src', img);
      $('.product_image').attr('src', img);
      //$('.zoomImg').attr('src', img);

    });

  }

  const imageChange = async () => {

    // $('.decorbrandlogosbody').on('click', function(){
    //   if($('.decorsubimg1').hasClass('decorsubimg2')){

    //   }
    // });

    $('.decorbrandlogosbody div').on('click', function(){

      var subCheck = $('.subimg2').attr('style');
      //alert(subCheck);
      if (subCheck === 'background-image:url("");'){
        $('.subimg2').fadeOut();  
        
          $('.subimg1').addClass('decorsubimg2');
        
      }
      else{
        $('.subimg2').fadeIn();
        $('.subimg1').removeClass('decorsubimg2');
      }
    });

    $('.decorbrandlogosbody .r-product').on('click', function(){

      var img = $(this).find('.r-image').attr('src');
      var title = $(this).find('.r-detail').find('.r-title').text();

      var img2 = $(this).find('.r-detail').find('.r-subimg1').text();
      var img3 = $(this).find('.r-detail').find('.r-subimg2').text();

      $('.decorheadmain span').text(title); 

      //alert(img2, img3);

      // $('.subimg1').attr('src', img2);
      // $('.subimg2').attr('src', img3);

      $('.subimg1').attr('data-img', img2);
      $('.subimg2').attr('data-img', img3);

      $('.subimg1').attr('style', 'background-image:'+'url("'+img2+'");');
      $('.subimg2').attr('style', 'background-image:'+'url("'+img3+'");');

      $('#product_image').attr('src', img);
      $('.zoomImg').attr('src', img);

      $('.decorheadmain span').text(title);

      // $('.decorbrandlogosbody img').removeClass('active-slide');
      // $('.decorbrandlogosbody img').removeClass('previous');
      // $('.decorbrandlogosbody img').removeClass('next');
    
      // $(this).prev().addClass('previous');
      // $(this).addClass('active-slide');
      // $(this).next().addClass('next');
      
      $('.decimg img').attr('src', img);
      $('#product_image').attr('src', img);
      $('.zoomImg').attr('src', img);

      $('.close2').addClass('d-none');
      $('.popup-top').removeClass('d-none');
      $('.dds-control2').addClass('d-none');

      // var slide = $(this).attr('data-slide');
      // $('.centered-div img').attr('data-slide', slide);

    });

    $('.decorbrandlogosbody .m-product').on('click', function(){

      var img0 = $(this).find('.m-image').attr('src');
      var title0 = $(this).find('.m-detail').find('.m-title').text();

      var img02 = $(this).find('.m-detail').find('.m-subimg1').text();
      var img03 = $(this).find('.m-detail').find('.m-subimg2').text();

      // $('.subimg1').attr('src', img02);
      // $('.subimg2').attr('src', img03);

      $('.subimg1').attr('data-img', img02);
      $('.subimg2').attr('data-img', img03);

      $('.subimg1').attr('style', 'background-image:'+'url("'+img02+'");');
      $('.subimg2').attr('style', 'background-image:'+'url("'+img03+'");');

      

      $('#product_image').attr('src', img0);

      $('.decorheadmain span').text(title0);

      // $('.decorbrandlogosbody img').removeClass('active-slide');
      // $('.decorbrandlogosbody img').removeClass('previous');
      // $('.decorbrandlogosbody img').removeClass('next');
    
      // $(this).prev().addClass('previous');
      // $(this).addClass('active-slide');
      // $(this).next().addClass('next');
      
      $('.decimg img').attr('src', img0);
      $('#product_image').attr('src', img0);

      

      // var slide = $(this).attr('data-slide');
      // $('.centered-div img').attr('data-slide', slide);

      $('.close2').addClass('d-none');
      $('.popup-top').removeClass('d-none');
      $('.dds-control2').addClass('d-none');

    });


    var slide = 0;

    // $('.decorbrandlogosbody img').each( function(){
    //      $(this).attr("data-slide", slide++);
    // }); 

  }

  const subImage = async () => {

    $('.decorsubimg1').on('click', function(){

      //var img = $(this).attr('src');

      var img = $(this).attr('data-img');
      //alert(img);
      
      //$('.decimg img').attr('src', img);
      $('.product_image').attr('src', img);
      //$('.zoomImg').attr('src', img);
      
      $('.centered-div2').fadeIn();

      $('.popup-top').addClass('d-none');

      $('.close2').removeClass('d-none');

      $('.dds-control2').removeClass('d-none');

      $('.left2').addClass('left-key2');
      $('.right2').addClass('right-key2');

      $('.left').removeClass('left-key');
      $('.right').removeClass('right-key');

      // $('.centered-div').append('<div class="close">x</div>');

      //$('.decorbrandlogosbody img:first-child').addClass('next');
  
      
    });

  }

  useLayoutEffect(() => {
   
    imageZoom();
    
  }, []);

  useEffect(() => {
   
    subImage();
    
  }, []);

  useEffect(() => {
   
    jQueryGeneral();
    
  }, []);

  const jQueryGeneral = async () => {
    $("body").keyup(function(e) {
  
      var code = e.keyCode || e.which; //Find the key pressed
    
            if (code == 39) 
            {  
              $('.right-key').click();
              $('.right-key2').click();
            }
            if(code == 37)
            {
              $('.left-key').click();
              $('.left-key2').click();
            }
    
    });
  }

  const imageZoom = async () => {

    $('.close').on('click', function(){
        $('.centered-div').fadeOut();
        $('.centered-div2').fadeOut();
    });

    $('.zoom').on('mouseover', function(){
      $('.zoomImg').css('width', '900px');
      $('.zoomImg').css('height', '1400px');
    });

    $('.lb-btn').on('click', function(){

      var img = $('.decimg img').attr('src');
      $('#product_image').attr('src', img);
      $('.zoomImg').attr('src', img);
      
      $('.centered-div').fadeIn();

      $('.close2').addClass('d-none');
      $('.popup-top').removeClass('d-none');
      $('.dds-control2').addClass('d-none');

      $('.left').addClass('left-key');
      $('.right').addClass('right-key');

      $('.left2').removeClass('left-key2');
      $('.right2').removeClass('right-key2');

      var id = 0;
      $(".cc-popup").empty();
      $('.decorbrandlogosbody img').each(function() {
        id++;
        var src = $(this).attr('src');
        $(".cc-popup").append('<img class id="cct-' + id + '" style="width: 50px; height: 50px; float: left; margin-top: 25px; margin-left: 10px; cursor: pointer; border-radius: 5px;" src="' + src + '"/>');

      });

      $('.cc-popup img:first-child').addClass('active-slide');
      $('.cc-popup img:nth-child(2)').addClass('next');

    });

    $('.left').on('click', function(){
      $('.previous').click();
    });

    $('.right').on('click', function(){
      $('.next').click();
    });

    $('.left2').on('click', function(){
      $('.previous2').click();
    });

    $('.right2').on('click', function(){
      $('.next2').click();
    });

    var native_width = 0;
    var native_height = 0;

    $(".magnifier").mousemove(function(e) {
      if (!native_width && !native_height) {
        var image_object = new Image();
        image_object.src = $(".small").attr("src");
        native_width = image_object.width;
        native_height = image_object.height;
      } else {
        var magnify_offset = $(this).offset();
        var mx = e.pageX - magnify_offset.left;
        var my = e.pageY - magnify_offset.top;
  
        if (mx < $(this).width() && my < $(this).height() && mx > 0 && my > 0) {
          $(".large").fadeIn(100);
        } else {
          $(".large").fadeOut(100);
        }
        if ($(".large").is(":visible")) {
          var rx =
            Math.round(
              mx / $(".small").width() * native_width - $(".large").width() / 2
            ) * -1;
          var ry =
            Math.round(
              my / $(".small").height() * native_height - $(".large").height() / 2
            ) * -1;
  
          var bgp = rx + "px " + ry + "px";
  
          var px = mx - $(".large").width() / 2;
          var py = my - $(".large").height() / 2;
  
          var background_url = $('#product_image').attr('src');
          var large_background = "url('"+background_url+"')";
  
          $(".large").css({ background: large_background, left: px, top: py, backgroundPosition: bgp });
  
        }
      }
    });
    
    
    $(".magnifier").mouseleave(function(){
      $(".large").fadeTo("slow", 0);
    });

    $(".magnifier").mouseenter(function(){
          $(".large").fadeTo("slow", 1);
    });

    $('#arm').bind('touchstart', function() {
      $(".large").fadeTo("slow", 0);
    });

    $('#arm').bind('touchend', function() {
      $(".large").fadeTo("slow", 1);
    });

  };


  const getoneprod = async () => {

    console.log('mazin one product hit ')
    const res = await axios.get(`https://alfinaltwo.reforce.com.pk/alnoor/product-desc/`+product_slug);
    if (res.status === 200) {
      setproductdetails(res.data.data)
      console.log('mazin true status  one product')
      console.log(res)
    }

  };


  const [productdetails1, setproductdetails1] = useState([]);

  useEffect(() => {

  getoneprod1();

}, []);

  var product_cat = productdetails.product_id;
  //alert(product_cat);

  const getoneprod1 = async () => {

    // Object.keys(productdetails).map((i, j) =>  (  

    // ));

      // productdetails.map((productdetail) => {product_cat = productdetail.product_id});

      const res = await axios.get('https://alfinaltwo.reforce.com.pk/alnoor/product-desc/related-products/'+product_slug)
      setproductdetails1(res.data.data)
      console.log(`https://alfinaltwo.reforce.com.pk/alnoor/product-desc/related-products/`+product_slug)
      console.log(res.data.data)

  };

  
  return (
    <div>

<div className="headerboxshadow">
      <Header />
      </div> 
    

          <div className="decorbody">


            <div className="decormainimg">








            <div
            
            >
              <div className="decimg mb-5" style={{marginTop: "28px"}}>

                <img

                style={{
                  overflow: "hidden",
                  boxShadow: "2px 5px 8px 4px rgba(0, 0, 0, 0.4)",
                  borderRadius: "50px",
                }}

                  className="lb-btn main-image"
                  width="544px"
                  height="609px"
                  src={productdetails.product_image}
                  alt="Alnoor"
                  objectFit="cover"

                />

              </div>
            </div>

<div className="centered-div2 img-popop-dl" data-modal="dbl-02" style={style1}>

<div className="close" data-modal="dbl-02">x</div>

<div onClick={topImgthumb2} className='dds-control dds-control2 d-none'>
    <i class="left2 fa-solid fa-angle-left"></i>
    <i class="right2 fa-solid fa-angle-right"></i>
  </div>

<div className="img-container d-flex align-items-center justify-content-center" style={{zoom: "20%", marginTop: "6%", marginBottom: "50px"}}>
  {/* <div className="large"></div> */}
  <img alt="img0" className="product_image" src="https://dummyimage.com/600x400/5c5c5c/bdbdbd.jpg"/>
</div>


</div>

            <div onMouseEnter={topImgthumb} className="centered-div" style={style1}>

            <div onMouseEnter={topImgthumb2}>

            {/* <div style={{marginTop: "-30px"}} className="close close2 d-none text-white">x</div> */}

              <div onMouseEnter={jQueryGeneral}>
              
              <div onMouseMove={imageChange} className="popup-top">

              <div className="close">x</div>

              <div onClick={topImgthumb} className='dds-control'>
                <i class="left fa-solid fa-angle-left"></i>
                <i class="right fa-solid fa-angle-right"></i>
              </div>

              <div className="cc-popup pl-3">
              
              {/* {productdetails.product_image ? (
                  <div style={{width: "50px", height: "50px", float: "left", marginTop: "25px", marginLeft: "20px", cursor: "pointer", borderRadius: "5px"}} className="colorCollectionTop" id="cct-1"><motion.img
                  whileHover={{ scale:0.98 }}
                transition={{  ease: "easeIn",duration: 0.2 }}
                 width="100%"
                 height="100%"
                 src={productdetails.product_image}
                 alt="Alnoor"
                 objectFit="cover"
               /></div>
                  ) : (
                    ""
                  )}
                
                {productdetails.product_color_img1 ? (
                  <div style={{width: "50px", height: "50px", float: "left", marginTop: "25px", marginLeft: "10px", cursor: "pointer", borderRadius: "5px"}} className="colorCollectionTop" id="cct-2"><motion.img
                  whileHover={{ scale:0.98 }}
                transition={{  ease: "easeIn",duration: 0.2 }}
                 width="100%"
                 height="100%"
                 src={productdetails.product_color_img1}
                 alt="Alnoor"
                 objectFit="cover"
               /></div>
                  ) : (
                    ""
                  )}

              {productdetails.product_color_img2 ? (
                  <div style={{width: "50px", height: "50px", float: "left", marginTop: "25px", marginLeft: "10px", cursor: "pointer", borderRadius: "5px"}} className="colorCollectionTop" id="cct-3"><motion.img
                  whileHover={{ scale:0.98 }}
                transition={{  ease: "easeIn",duration: 0.2 }}
                 width="100%"
                 height="100%"
                 src={productdetails.product_color_img2}
                 alt="Alnoor"
                 objectFit="cover"
               /></div>
                  ) : (
                    ""
                  )}


              {productdetails.product_color_img3 ? (
                  <div style={{width: "50px", height: "50px", float: "left", marginTop: "25px", marginLeft: "10px", cursor: "pointer", borderRadius: "5px"}} className="colorCollectionTop" id="cct-4"><motion.img
                  whileHover={{ scale:0.98 }}
                transition={{  ease: "easeIn",duration: 0.2 }}
                 width="100%"
                 height="100%"
                 src={productdetails.product_color_img3}
                 alt="Alnoor"
                 objectFit="cover"
               /></div>
                  ) : (
                    ""
                  )}

                {productdetails.product_color_img4 ? (
                  <div style={{width: "50px", height: "50px", float: "left", marginTop: "25px", marginLeft: "10px", cursor: "pointer", borderRadius: "5px"}} className="colorCollectionTop" id="cct-5"><motion.img
                  whileHover={{ scale:0.98 }}
                transition={{  ease: "easeIn",duration: 0.2 }}
                 width="100%"
                 height="100%"
                 src={productdetails.product_color_img4}
                 alt="Alnoor"
                 objectFit="cover"
               /></div>
                  ) : (
                    ""
                  )} */}

            </div>

              {/* <p className="image-title-popup">{productdetails.product_name}</p> */}
              
              
              </div>

            {/* <div class="magnifier">
              <div class="large"></div>
              <img id="product_image" data-slide="0" class="small img-fluid decorImg" src={productdetails.product_image} width="640"/>
            </div> */}

            <center>

              <div className="zoom mt-5 mb-5" id='produto' style={{overflow: "hidden", height: "900px", width: "600px"}}>
                <img id="product_image" className="my-image decorImg" src="https://res.cloudinary.com/active-bridge/image/upload/slide1.jpg" />
                <div id="target"></div>
              </div>

            </center>

            </div>
            </div>
            </div>



            {productdetails.product_image4 ? (
                   <div style={{width:"100%",marginLeft:"auto",marginRight:"auto",marginTop:"10px",marginBottom:"10px"}}>
                   <img
                     width="548px"
                     height="226px"
                     src={productdetails.product_image4}
                     alt="Alnoor"
                     objectFit="contain"
                   />
                   </div>
                   
                  ) : (
                    ""
                  )}




{productdetails.product_image3 ? (

  <div onMouseMove={imageZoom, imageChange} className="decorsubimg" style={{width:"188%"}}>

    {/* <img className="subimgtag1 active-slide2" style={{display: "none"}} src={productdetails.product_image2}></img>
    <img className="subimgtag2 next2" style={{display: "none"}} src={productdetails.product_image3}></img> */}

    {productdetails.product_image2 ? (
             
      <div onMouseOver={subImage} onMouseMove={subImgFix} className="lb-btn decorsubimg1 subimg1"
        alt="Alnoor"
        objectFit="cover"
        data-img={productdetails.product_image2}
        style={{backgroundImage: `url(${'"'+productdetails.product_image2+'"'})`}}
      ></div>

    ) : (
      ""
    )}



    {productdetails.product_image3 ? (
             
      <div onMouseOver={subImage} onMouseMove={subImgFix} className="lb-btn decorsubimg1 subimg2"
        alt="Alnoor"
        objectFit="cover"
        data-img={productdetails.product_image3}
        style={{backgroundImage: `url(${'"'+productdetails.product_image3+'"'})`}}
      ></div>
 
    ) : (
      ""
    )}



  </div>
) : (


    <div>

    {productdetails.product_image2 ? (

      <div onMouseMove={imageZoom, imageChange} className="decorsubimg" style={{width:"188%"}}>
        
        <div onMouseOver={subImage} onMouseMove={subImgFix} className="lb-btn decorsubimg1 decorsubimg2 subimg1"
          alt="Alnoor"
          objectFit="cover"
          data-img={productdetails.product_image2}
          style={{backgroundImage: `url(${'"'+productdetails.product_image2+'"'})`}}
        ></div>

        <div style={{boxShadow:"none"}} onMouseOver={subImage} onMouseMove={subImgFix} className="lb-btn decorsubimg1 subimg2">

        </div>

        </div>
  
      ) : (
        ""
      )}



    {/* {productdetails.product_image3 ? (
            
      <img onMouseOver={subImage} onMouseMove={subImgFix} className="lb-btn decorsubimg1 subimg2"
        src={productdetails.product_image3}
        alt="Alnoor"
        objectFit="cover"
        style={{width:"23.4%"}}
      />

    ) : (
      ""
    )} */}


  </div>


  
)}


{/* {productdetails.product_image2 ? (
             <div onMouseMove={imageZoom, imageChange} className="decorsubimg" style={{width:"188%"}}>


            {productdetails.product_image2 ? (
  
              <img onMouseOver={subImage} onMouseMove={subImgFix} className="lb-btn decorsubimg1 subimg1"
               src={productdetails.product_image2}
               alt="Alnoor"
               objectFit="cover"
               style={{width:"23.4%"}}

              

              />  
                   
            ) : (
              ""
            )}







{productdetails.product_image3 ? (
             


<img onMouseOver={subImage} onMouseMove={subImgFix} className="lb-btn decorsubimg1 subimg2"
               src={productdetails.product_image3}
               alt="Alnoor"
               objectFit="cover"
               style={{width:"23.4%"}}
             />


            
          
             
                   
                  ) : (
                    ""
                  )}



            
             </div>
             
                   
                  ) : (
                    ""
                  )}

 */}



            </div>

           
            <div className="decortextbody" >

              <p className="decorheadmain"  > Décor: <span>{productdetails.product_name}</span></p>
           

                {/* {productdetails1.map((productdetail) => {

                  

                  return <ul key={productdetail.product_id}>
                      
                      <li>{productdetail.product_id}</li>
                      
                      </ul>

                  })

                }  */}

{/* {Object.keys(productdetails1).map((i, j) =>  (
                
               

                <div className="" key={productdetails1[i].product_id}>

                <div className="">
                    <p>{productdetails1[i].product_id}</p>
                </div>

                </div>


            ))} */}

              <div className="decorsubheadmaindiv" >
              <p className="decorsubheadmain" >Decor Type:</p>
              <p className="decorsubheadmain2" >{productdetails.product_type}</p></div>

              <p className="decortext" >{productdetails.product_short_desc}</p>









              {productdetails.product_finish_img1 ? (
                  <p className="decorsubheadmain3" >Available Finish:</p>
                  ) : (
                    ""
                  )}






{productdetails.product_finish_img1 ? (
                   <div className="decorbrands">
                
                {productdetails.product_finish_img1 ? (
                   
                   <div className="decorbrandlogos">
     
                   <motion.img
                    whileHover={{ scale:1.2 }}
                    transition={{  ease: "easeIn",duration: 0.2 }}
                     width="100%"
                     height="100%"
                     src={productdetails.product_finish_img1}
                     alt="Alnoor"
                     objectFit="cover"
                    
                   />
                   </div>
                  ) : (
                    ""
                  )}






{productdetails.product_finish_img2 ? (
                   
                   <div className="decorbrandlogos">
     
                   <motion.img
                    whileHover={{ scale:1.2 }}
                    transition={{  ease: "easeIn",duration: 0.2 }}
                     width="100%"
                     height="100%"
                     src={productdetails.product_finish_img2}
                     alt="Alnoor"
                     objectFit="cover"
                    
                   />
                   </div>
                  ) : (
                    ""
                  )}






{productdetails.product_finish_img3 ? (
                   
                   <div className="decorbrandlogos">
     
                   <motion.img
                    whileHover={{ scale:1.2 }}
                    transition={{  ease: "easeIn",duration: 0.2 }}
                     width="100%"
                     height="100%"
                     src={productdetails.product_finish_img3}
                     alt="Alnoor"
                     objectFit="cover"
                    
                   />
                   </div>
                  ) : (
                    ""
                  )}






{productdetails.product_finish_img4 ? (
                   
                   <div className="decorbrandlogos">
     
                   <motion.img
                    whileHover={{ scale:1.2 }}
                    transition={{  ease: "easeIn",duration: 0.2 }}
                     width="100%"
                     height="100%"
                     src={productdetails.product_finish_img4}
                     alt="Alnoor"
                     objectFit="cover"
                    
                   />
                   </div>
                  ) : (
                    ""
                  )}





{productdetails.product_finish_img5 ? (
                   
                   <div className="decorbrandlogos">
     
                   <motion.img
                    whileHover={{ scale:1.2 }}
                    transition={{  ease: "easeIn",duration: 0.2 }}
                     width="100%"
                     height="100%"
                     src={productdetails.product_finish_img5}
                     alt="Alnoor"
                     objectFit="cover"
                    
                   />
                   </div>
                  ) : (
                    ""
                  )}
                  
                   
                   </div>
     
                  ) : (
                    ""
                  )}






            

































{/* <p className="decorsubheadmain3" >Collection Colors:</p>

<div className="decorbrandlogosbody" style={{width:"100%",display:"flex",flexDirection:"row"}}>

            <img
               src={productdetails.product_image}
             />

            {Object.keys(productdetails1).map((i, j) =>  (
 
                   

                    <motion.img
                    className="next"
                    whileHover={{ scale:1.05 }}
                    transition={{  ease: "easeIn",duration: 0.2 }}
                     width="100%"
                     height="100%"
                     src={productdetails1[i].product_image}
                     alt="Alnoor"
                     objectFit="cover"
                    
                   />

        

              

            ))}

          </div> */}


          <p className="decorsubheadmain3" >Collection Colors:</p>

          <div onMouseMove={imageChange} className="decorbrandlogosbody" style={{width:"100%",display:"flex",flexDirection:"row"}}>

            {/* <img src={productdetails.product_image} /> */}

            <div className="m-product">
                <img className="m-image" src={productdetails.product_image}></img>
                <div className="m-detail d-none">
                  <span className="m-title">{productdetails.product_name}</span>
                  <span className="m-subimg1">{productdetails.product_image2}</span>
                  <span className="m-subimg2">{productdetails.product_image3}</span>
                </div>
            </div>


          {Object.keys(productdetails1).map((i, j) =>  (
                


                <div className="r-product">
                    <img className="r-image" src={productdetails1[i].product_image}></img>
                    <div className="r-detail d-none">
                      <span className="r-title">{productdetails1[i].product_name}</span>
                      <span className="r-subimg1">{productdetails1[i].product_image2}</span>
                      <span className="r-subimg2">{productdetails1[i].product_image3}</span>
                    </div>
                </div>

                

            ))} 

          </div>



            </div>















          </div>
  


    

      


    <Footer/>

    </div>
  );
};

export default Dlist;

