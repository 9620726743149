import React from 'react'
import Layout from '../components/Layout/Index'
import Header from "../components/Header";
import Footer from "../components/Footer";

const OurDesignStudios = () => {
  return (
    <>
        <div className='headerboxshadow' >
      <Header />
      </div>
      <p className='subheadmain' >Technical Specifications</p>
      <div style={{ minHeight: "607px"}}>



        <img className='imgtop'
          src="/images/tspecs.png"
          alt="Alnoor"
          objectFit="cover"
        />
<p className='headbottom' >UV Coating Line</p>


<img className='imgbottom'
          
          src="/images/UVCL.png"
          alt="Alnoor"
          objectFit="cover"
        />
 

      </div>

      <Footer />
    </>
  )
}

export default OurDesignStudios