import React from 'react'
import Layout from '../components/Layout/Index'
import Header from "../components/Header";
import Footer from "../components/Footer";

const Techspecs = () => {
  return (
    <>

    <Header />

    <div className='container-fluid odc'>

    <h1 className='text-center mt-3 mb-5'>Our Dealership</h1>
    
    <div className="input-group mb-1 border p-1 inner-shadow-search">
      <input id="pac-input" type="text" placeholder="Search" aria-describedby="button-addon3" className="ods-input form-control bg-none border-0 controls" />
      <div className="input-group-append border-0">
        <button id="button-addon3" type="button" className="ods-btn btn btn-link text-black"><i className="fa-solid fa-location-crosshairs"></i></button>
      </div>
    </div>

    <div style={{minHeight:"700px",display:"flex",flexDirection:"row",paddingTop:"1.8%"}}>

      <div className='inner-shadow' id='restaurantmap'></div>
     
    </div>

    <br></br>
    <br></br>

    </div>

    <Footer />

    </>
  )
}

export default Techspecs