import React from 'react'
import Layout from '../components/Layout/Index'
import Header from "../components/Header";
import Footer from "../components/Footer";

const OurDesignStudios = () => {
  return (
    <>
          <div style={{boxShadow:"-7px 7px 45px 0px rgba(0, 0, 0, 0.1)"}}>
      <Header />
      </div>
      <p className='Submainhead' >Our Design Studios</p>
      <div className='Imgheight'>


        <img className='imgsize'
          src="/images/ostdban.png"
          alt="Alnoor"
          objectFit="cover"
        />

        <div className='upfooter' >


          <div className='upfootertext'>
            <p className='upfootertext1' >Karachi Design Studios</p>
            <p className='upfootertext2' >24-C, 7th Commercial Lane,<br />
              Main Khayaban-e-Bahria,<br />
              Phase IV , DHA, Karachi.<br /></p>
            <a href="https://goo.gl/maps/wDhR5uoHAQGnhThf9" target="_blank">
            <img className='imgupfooter'
              src="/images/pin-location.png"
              alt="Alnoor"
              objectFit="cover"
            />
            </a>
            <p className='upfooternum' >+92 21 372 372 05</p>


          </div>
          
          <img className='imgupfooter1'
            src="/images/ods-shadow.png"
            alt="Alnoor"
            objectFit="cover"
          />
          
          <div className='upfootertext' style={{ paddingRight:"50px"}}>
            <p className='upfootertext1'>Lahore Design Studios</p>
            <p className='upfootertext2'>7-A, Sector XX, Phase 3,<br />
             
              DHA, Lahore.<br /></p>
            <a href="https://goo.gl/maps/7UcJreS2GjDT7Jrf8" target="_blank">
            <img className='imgupfooter'
            
              src="/images/pin-location.png"
              alt="Alnoor"
              objectit="cover"
            />
            </a>
            <p className='upfooternum'>+92 21 372 372 05</p>
            <p className='upfooternum' style={{ marginTop: "-5px" }}>+92 21 372 372 05</p>







          </div>



        </div>

      </div>

      <Footer />
    </>
  )
}

export default OurDesignStudios