import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";
import "./App.css";
import HomePage from "./containers/Homepage";
import About from "./containers/About";
import Contactus from "./containers/Contactus";
import Decorslist from "./containers/Decorslist";
import Decorslist2 from "./containers/Decorslist2";
import Category from "./containers/Category";
import DecorsDetail from "./containers/DecorsDetail";
import Techspecs from "./containers/Techspecs";
import OurDesignStudios from "./containers/OurDesignStudios";
import OurDealership from "./containers/OurDealership";
import DesignDiaries from "./containers/DesignDiaries";
import TrendingDetails from "./containers/trendingdetails";
import DecorBooks from "./containers/DecorBooks";
import Dbook2 from "./containers/Dbook-2";
import Dbook3 from "./containers/Dbook-3";
import Dlist from "./containers/dlist";


function App() {
  return (
   
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="about" element={<About />} />
        <Route path="contact-us" element={<Contactus />} />
        <Route path="decors" element={<Decorslist />} />
        <Route path="decors2" element={<Decorslist2 />} />
        <Route path="category" element={<Category />} />
        <Route path="decorsdetail/:product_slug" exact element={<DecorsDetail/>} />
        <Route path="decorsdetail" element={<DecorsDetail/>} />
        <Route path="technical-specifications" element={<Techspecs/>} />
        <Route path="our-design-studios" element={<OurDesignStudios/>} />
        <Route path="our-dealership" element={<OurDealership/>} />
        <Route path="design-diaries" element={<DesignDiaries/>} />
        <Route path="trending-details" element={<TrendingDetails/>} />
        <Route path="decor-books" element={<DecorBooks/>} />
        <Route path="decor-book-2" element={<Dbook2 />} />
        <Route path="decor-book-3" element={<Dbook3 />} />
        <Route path="dlist/:product_slug" exact element={<Dlist />} />
      </Routes>
  );
}

export default App;