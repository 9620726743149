import React, {useEffect} from 'react'
import Layout from '../components/Layout/Index'
import Header from "../components/Header";
import Footer from "../components/Footer";

import $ from 'jquery';



const style1 = {display: "none"};

const imageZoom = async () => {



  var slide = 1;
  var usemap = 1;

    //  $('.sec1dd img').each( function(){
    //       $(this).attr("data-slide", slide++);
    //  });
     
    //  $('.sec1dd .map-data').each( function(){
    //       $(this).attr("data-usemap", usemap++);
    //  });

  $('.sec1dd img').on('click', function(){

    var src = $(this).attr('src');
    $('.product_image').attr('src', src)
    $('.centered-div').fadeIn();

    var slide = $(this).attr('data-slide');
    $('.centered-div img').attr('data-slide', slide);

    //alert(src);

  });

  $('.sec1dd .map-data').on('click', function(){

    var usemap_id = $(this).attr('data-usemap');
    $('.product_image').attr('usemap', '#'+usemap_id);

    $('.slide-container map').attr('name', usemap_id);

    $('.product_image').attr('style', 'height:auto; width:100%; zoom: auto;');

    // First pointer

    var mc1Top = $(this).find('.map-coordinates').find('.mc1-top').text()  + "%";
    var mc1Left = $(this).find('.map-coordinates').find('.mc1-left').text()  + "%";
    var mc1_link = $(this).find('.map-coordinates').find('.mc1-link').text();
    var mc1_title = $(this).find('.map-coordinates').find('.mc1-title').text();

    if (mc1_title != "") {

      $('#popover-btn').attr('style', 'display: block; left:'+mc1Left+'; top:'+mc1Top+'; position:fixed; height:15px; width:15px; border-radius:50%; position: absolute; padding:3px; border: 1px solid #fff; background-color: #fff; background-color: rgba(255,255,255,0.4);');
      $('#popover-btn').html("<span href='#' class='pointer'><div class='pointer-circle'></div></span><div class='popover' style='display:none;'><div class='popover-inner'><a href='"+mc1_link+"' target='_blank'><div class='popover-heading'>View</div><div class='float-right' style='color:#000; margin-top: 1px;'><i class='fa fa-angle-right'></i></div></a><br><div class='popover-content'>"+mc1_title+"</div></div></div>");

    }

    else {
      $('#popover-btn').attr('style', 'display:none');
    }

    // Second pointer

    var mc2Top = $(this).find('.map-coordinates').find('.mc2-top').text()  + "%";
    var mc2Left = $(this).find('.map-coordinates').find('.mc2-left').text()  + "%";
    var mc2_link = $(this).find('.map-coordinates').find('.mc2-link').text();
    var mc2_title = $(this).find('.map-coordinates').find('.mc2-title').text();

    if (mc2_title != "") {

      $('#popover-btn2').attr('style', 'display: block; left:'+mc2Left+'; top:'+mc2Top+'; position:fixed; height:15px; width:15px; border-radius:50%; position: absolute; padding:3px; border: 1px solid #fff; background-color: #fff; background-color: rgba(255,255,255,0.4);');
      $('#popover-btn2').html("<span href='#' class='pointer'><div class='pointer-circle'></div></span><div class='popover' style='display:none;'><div class='popover-inner'><a href='"+mc2_link+"' target='_blank'><div class='popover-heading'>View</div><div class='float-right' style='color:#000; margin-top: 1px;'><i class='fa fa-angle-right'></i></div></a><br><div class='popover-content'>"+mc2_title+"</div></div></div>");

    }

    else {
      $('#popover-btn2').attr('style', 'display:none');
    }

    // Third pointer

    var mc3Top = $(this).find('.map-coordinates').find('.mc3-top').text()  + "%";
    var mc3Left = $(this).find('.map-coordinates').find('.mc3-left').text()  + "%";
    var mc3_link = $(this).find('.map-coordinates').find('.mc3-link').text();
    var mc3_title = $(this).find('.map-coordinates').find('.mc3-title').text();

    if (mc3_title != "") {

      $('#popover-btn3').attr('style', 'display: block; left:'+mc3Left+'; top:'+mc3Top+'; position:fixed; height:15px; width:15px; border-radius:50%; position: absolute; padding:3px; border: 1px solid #fff; background-color: #fff; background-color: rgba(255,255,255,0.4);');
      $('#popover-btn3').html("<span href='#' class='pointer'><div class='pointer-circle'></div></span><div class='popover' style='display:none;'><div class='popover-inner'><a href='"+mc3_link+"' target='_blank'><div class='popover-heading'>View</div><div class='float-right' style='color:#000; margin-top: 1px;'><i class='fa fa-angle-right'></i></div></a><br><div class='popover-content'>"+mc3_title+"</div></div></div>");

    }

    else {
      $('#popover-btn3').attr('style', 'display:none');
    }

  });

  $('.close').on('click', function(){
      $('.centered-div').fadeOut();
  });


  $('.right').on('click', function(){

    var slide = $('.centered-div img').attr('data-slide');
    var total_slides = $('.sec1dd img').length;

    if (slide < total_slides) {
      slide++;
    }

    $('.centered-div img').attr('data-slide', slide);
    var path = $('.sec1dd img[data-slide = "' + slide + '"]').attr('src');
    var usemap_id = slide;
    $('.centered-div img').attr('src', path);
    $('.centered-div img').attr('id', usemap_id);

    $('.product_image').attr('style', 'height:auto; width:100%; zoom: auto;');

    // First pointer

    var mc1Top = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc1-top').text()  + "%";
    var mc1Left = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc1-left').text()  + "%";
    var mc1_link = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc1-link').text();
    var mc1_title = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc1-title').text();

    if (mc1_title != "") {

      $('#popover-btn').attr('style', 'display: block; left:'+mc1Left+'; top:'+mc1Top+'; position:fixed; height:15px; width:15px; border-radius:50%; position: absolute; padding:3px; border: 1px solid #fff; background-color: #fff; background-color: rgba(255,255,255,0.4);');
      $('#popover-btn').html("<span href='#' class='pointer'><div class='pointer-circle'></div></span><div class='popover' style='display:none;'><div class='popover-inner'><a href='"+mc1_link+"' target='_blank'><div class='popover-heading'>View</div><div class='float-right' style='color:#000; margin-top: 1px;'><i class='fa fa-angle-right'></i></div></a><br><div class='popover-content'>"+mc1_title+"</div></div></div>");

    }

    else {
      $('#popover-btn').attr('style', 'display:none');
    }

    // Second pointer

    var mc2Top = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc2-top').text()  + "%";
    var mc2Left = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc2-left').text()  + "%";
    var mc2_link = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc2-link').text();
    var mc2_title = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc2-title').text();

    if (mc2_title != "") {

      $('#popover-btn2').attr('style', 'display: block; left:'+mc2Left+'; top:'+mc2Top+'; position:fixed; height:15px; width:15px; border-radius:50%; position: absolute; padding:3px; border: 1px solid #fff; background-color: #fff; background-color: rgba(255,255,255,0.4);');
      $('#popover-btn2').html("<span href='#' class='pointer'><div class='pointer-circle'></div></span><div class='popover' style='display:none;'><div class='popover-inner'><a href='"+mc2_link+"' target='_blank'><div class='popover-heading'>View</div><div class='float-right' style='color:#000; margin-top: 1px;'><i class='fa fa-angle-right'></i></div></a><br><div class='popover-content'>"+mc2_title+"</div></div></div>");

    }

    else {
      $('#popover-btn2').attr('style', 'display:none');
    }

    // Third pointer

    var mc3Top = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc3-top').text()  + "%";
    var mc3Left = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc3-left').text()  + "%";
    var mc3_link = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc3-link').text();
    var mc3_title = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc3-title').text();

    if (mc3_title != "") {

      $('#popover-btn3').attr('style', 'display: block; left:'+mc3Left+'; top:'+mc3Top+'; position:fixed; height:15px; width:15px; border-radius:50%; position: absolute; padding:3px; border: 1px solid #fff; background-color: #fff; background-color: rgba(255,255,255,0.4);');
      $('#popover-btn3').html("<span href='#' class='pointer'><div class='pointer-circle'></div></span><div class='popover' style='display:none;'><div class='popover-inner'><a href='"+mc3_link+"' target='_blank'><div class='popover-heading'>View</div><div class='float-right' style='color:#000; margin-top: 1px;'><i class='fa fa-angle-right'></i></div></a><br><div class='popover-content'>"+mc3_title+"</div></div></div>");

    }

    else {
      $('#popover-btn3').attr('style', 'display:none');
    }

  });


  $('.left').on('click', function(){

    var slide = $('.centered-div img').attr('data-slide');

    if (slide > 1) {
      slide--;
    }

    $('.centered-div img').attr('data-slide', slide);
    var path = $('.sec1dd img[data-slide = "' + slide + '"]').attr('src');
    var usemap_id = slide;
    $('.centered-div img').attr('src', path);
    $('.centered-div img').attr('id', usemap_id);

    $('.product_image').attr('style', 'height:auto; width:100%; zoom: auto;');

    // First pointer

    var mc1Top = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc1-top').text()  + "%";
    var mc1Left = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc1-left').text()  + "%";
    var mc1_link = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc1-link').text();
    var mc1_title = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc1-title').text();

    if (mc1_title != "") {

      $('#popover-btn').attr('style', 'display: block; left:'+mc1Left+'; top:'+mc1Top+'; position:fixed; height:15px; width:15px; border-radius:50%; position: absolute; padding:3px; border: 1px solid #fff; background-color: #fff; background-color: rgba(255,255,255,0.4);');
      $('#popover-btn').html("<span href='#' class='pointer'><div class='pointer-circle'></div></span><div class='popover' style='display:none;'><div class='popover-inner'><a href='"+mc1_link+"' target='_blank'><div class='popover-heading'>View</div><div class='float-right' style='color:#000; margin-top: 1px;'><i class='fa fa-angle-right'></i></div></a><br><div class='popover-content'>"+mc1_title+"</div></div></div>");

    }

    else {
      $('#popover-btn').attr('style', 'display:none');
    }

    // Second pointer

    var mc2Top = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc2-top').text()  + "%";
    var mc2Left = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc2-left').text()  + "%";
    var mc2_link = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc2-link').text();
    var mc2_title = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc2-title').text();

    if (mc2_title != "") {

      $('#popover-btn2').attr('style', 'display: block; left:'+mc2Left+'; top:'+mc2Top+'; position:fixed; height:15px; width:15px; border-radius:50%; position: absolute; padding:3px; border: 1px solid #fff; background-color: #fff; background-color: rgba(255,255,255,0.4);');
      $('#popover-btn2').html("<span href='#' class='pointer'><div class='pointer-circle'></div></span><div class='popover' style='display:none;'><div class='popover-inner'><a href='"+mc2_link+"' target='_blank'><div class='popover-heading'>View</div><div class='float-right' style='color:#000; margin-top: 1px;'><i class='fa fa-angle-right'></i></div></a><br><div class='popover-content'>"+mc2_title+"</div></div></div>");

    }

    else {
      $('#popover-btn2').attr('style', 'display:none');
    }

    // Third pointer

    var mc3Top = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc3-top').text()  + "%";
    var mc3Left = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc3-left').text()  + "%";
    var mc3_link = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc3-link').text();
    var mc3_title = $('#mdd' + usemap_id).find('.map-coordinates').find('.mc3-title').text();

    if (mc3_title != "") {

      $('#popover-btn3').attr('style', 'display: block; left:'+mc3Left+'; top:'+mc3Top+'; position:fixed; height:15px; width:15px; border-radius:50%; position: absolute; padding:3px; border: 1px solid #fff; background-color: #fff; background-color: rgba(255,255,255,0.4);');
      $('#popover-btn3').html("<span href='#' class='pointer'><div class='pointer-circle'></div></span><div class='popover' style='display:none;'><div class='popover-inner'><a href='"+mc3_link+"' target='_blank'><div class='popover-heading'>View</div><div class='float-right' style='color:#000; margin-top: 1px;'><i class='fa fa-angle-right'></i></div></a><br><div class='popover-content'>"+mc3_title+"</div></div></div>");

    }

    else {
      $('#popover-btn3').attr('style', 'display:none');
    }


  });

  // $('.swiper-button-next, .swiper-button-prev').attr('style', 'display:none');

$("body").keyup(function(e) {

  var code = e.keyCode || e.which; //Find the key pressed

        if (code == 39) 
        {  
          $('.right').click();
        }
        if(code == 37)
        {
          $('.left').click();
        }

});



};


const OurDesignStudios = () => {

  useEffect(() => {

    $(document).ready(function() {

      $('.absolute').on('mouseenter', function(){
        $(this).find('.popover').fadeIn();
      });
    
      $('.absolute').on('mouseleave', function(){
        $(this).find('.popover').fadeOut();
      });

    });

  }, []);


  useEffect(() => {
   
    imageZoom();
    
  }, []);


  return (
    <>
      <div className='headerboxshadow'>
      <Header />
      </div>





      {/* <img
        src="/images/headdow.jpg"
        alt="Alnoor"
        objectFit="cover"
      /> */}

 

      <p className='mainheaddd' >Design Diaries</p>









    <div className="container-fluid sec1dd pr-4">
    <div className="d-flex flex-row flex-wrap justify-content-center">
        <div className="d-flex flex-column">
          
            <div id="mdd1" className='map-data' data-usemap="1">
               <img src="/images/dd/dd1.png" className="w-100 h-100 m-2 p-2" data-slide="1"></img>
                <div className='map-coordinates'>

                  <spin className='mc1-top'>12</spin>
                  <spin className='mc1-left'>59</spin>

                  <spin className='mc1-link'>/decorsdetail/s008-bermuda</spin>
                  <spin className='mc1-title'>Bermuda S008</spin>

                  <spin className='mc2-top'>68</spin>
                  <spin className='mc2-left'>80.5</spin>
          
                  <spin className='mc2-link'>/decorsdetail/2031-bottle-green</spin>
                  <spin className='mc2-title'>2031</spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
          
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>
  
                </div>
            </div>  

            <div id="mdd2" className='map-data' data-usemap="2">
              <img src="/images/dd/DD2.png" className="w-100 h-100 m-2 p-2" data-slide="2"></img>
                <div className='map-coordinates'>

                  <spin className='mc1-top'></spin>
                  <spin className='mc1-left'></spin>

                  <spin className='mc1-link'></spin>
                  <spin className='mc1-title'></spin>

                  <spin className='mc2-top'></spin>
                  <spin className='mc2-left'></spin>
          
                  <spin className='mc2-link'></spin>
                  <spin className='mc2-title'></spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>
          
                </div>
            </div>
            
            <div id="mdd7" className='map-data' data-usemap="7">
              <img src="/images/dd/dd7.png" className="w-100 h-100 m-2 p-2" data-slide="7"></img>
                <div className='map-coordinates'>

                  <spin className='mc1-top'>50.5</spin>
                  <spin className='mc1-left'>86.5</spin>

                  <spin className='mc1-link'>/decorsdetail/2028-charcoal-grey</spin>
                  <spin className='mc1-title'>2028</spin>

                  <spin className='mc2-top'></spin>
                  <spin className='mc2-left'></spin>
          
                  <spin className='mc2-link'></spin>
                  <spin className='mc2-title'></spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
          
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>
                  
                </div>
            </div>

            <div id="mdd8" className='map-data' data-usemap="8">
              <img src="/images/dd/dd8.png" className="w-100 h-100 m-2 p-2" data-slide="8"></img>
                <div className='map-coordinates'>

                <spin className='mc1-top'>60</spin>
                  <spin className='mc1-left'>48</spin>

                  <spin className='mc1-link'>/decorsdetail/4031</spin>
                  <spin className='mc1-title'>4031</spin>

                  <spin className='mc2-top'></spin>
                  <spin className='mc2-left'></spin>
          
                  <spin className='mc2-link'></spin>
                  <spin className='mc2-title'></spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
          
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>

                </div>
            </div>

            <div id="mdd11" className='map-data' data-usemap="11">
              <img src="/images/dd/dd11.png" className="w-100 h-100 m-2 p-2" data-slide="11"></img>
                <div className='map-coordinates'>

                  <spin className='mc1-top'></spin>
                  <spin className='mc1-left'></spin>

                  <spin className='mc1-link'></spin>
                  <spin className='mc1-title'></spin>

                  <spin className='mc2-top'></spin>
                  <spin className='mc2-left'></spin>
          
                  <spin className='mc2-link'></spin>
                  <spin className='mc2-title'></spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
          
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>

                </div>
            </div>

            <div id="mdd15" className='map-data' data-usemap="15">
              <img src="/images/dd/dd15.png"  className="w-100 h-100 m-2 p-2" data-slide="15"></img>
                <div className='map-coordinates'>

                  <spin className='mc1-top'></spin>
                  <spin className='mc1-left'></spin>

                  <spin className='mc1-link'></spin>
                  <spin className='mc1-title'></spin>

                  <spin className='mc2-top'></spin>
                  <spin className='mc2-left'></spin>
          
                  <spin className='mc2-link'></spin>
                  <spin className='mc2-title'></spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
          
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>

                </div>
            </div>

            <div id="mdd16" className='map-data' data-usemap="16">
              <img src="/images/dd/dd16.png"  className="w-100 h-100 m-2 p-2" data-slide="16"></img>
                <div className='map-coordinates'>

                  <spin className='mc1-top'></spin>
                  <spin className='mc1-left'></spin>

                  <spin className='mc1-link'></spin>
                  <spin className='mc1-title'></spin>

                  <spin className='mc2-top'></spin>
                  <spin className='mc2-left'></spin>
          
                  <spin className='mc2-link'></spin>
                  <spin className='mc2-title'></spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
          
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>

                </div>
            </div>
            
        </div>
        
        <div className="d-flex flex-column">

            <div id="mdd3" className='map-data' data-usemap="3">
              <img src="/images/dd/dd3.png" className="w-100 h-100 m-2 p-2" data-slide="3"></img>
                <div className='map-coordinates'>

                  <spin className='mc1-top'>35.5</spin>
                  <spin className='mc1-left'>61</spin>

                  <spin className='mc1-link'>/decorsdetail/4084</spin>
                  <spin className='mc1-title'>4084</spin>

                  <spin className='mc2-top'></spin>
                  <spin className='mc2-left'></spin>
          
                  <spin className='mc2-link'></spin>
                  <spin className='mc2-title'></spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
          
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>

                </div>
            </div>

            <div id="mdd4" className='map-data' data-usemap="4">
              <img src="/images/dd/dd4.png" className="w-100 h-100 m-2 p-2" data-slide="4"></img>
                <div className='map-coordinates'>

                  <spin className='mc1-top'></spin>
                  <spin className='mc1-left'></spin>

                  <spin className='mc1-link'></spin>
                  <spin className='mc1-title'></spin>

                  <spin className='mc2-top'></spin>
                  <spin className='mc2-left'></spin>
          
                  <spin className='mc2-link'></spin>
                  <spin className='mc2-title'></spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
          
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>

                </div>
            </div>

            <div id="mdd9" className='map-data' data-usemap="9">
              <img src="/images/dd/dd9.png" className="w-100 h-100 m-2 p-2" data-slide="9"></img>
              <div className='map-coordinates'>

                  <spin className='mc1-top'>29</spin>
                  <spin className='mc1-left'>73</spin>

                  <spin className='mc1-link'>/decorsdetail/s009-neapolis</spin>
                  <spin className='mc1-title'>Neapolis-S009</spin>

                  <spin className='mc2-top'></spin>
                  <spin className='mc2-left'></spin>
          
                  <spin className='mc2-link'></spin>
                  <spin className='mc2-title'></spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
          
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>

                </div>
            </div>

            <div id="mdd10" className='map-data' data-usemap="10">
              <img src="/images/dd/dd10.png" className="w-100 h-100 m-2 p-2" data-slide="10"></img>
              <div className='map-coordinates'>

                  <spin className='mc1-top'>35.3</spin>
                  <spin className='mc1-left'>76</spin>

                  <spin className='mc1-link'>/decorsdetail/s001-canyon-jura-pine</spin>
                  <spin className='mc1-title'>Canyon Jura Pine - S001</spin>

                  <spin className='mc2-top'></spin>
                  <spin className='mc2-left'></spin>
          
                  <spin className='mc2-link'></spin>
                  <spin className='mc2-title'></spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
          
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>

                </div>
            </div>

            <div id="mdd12" className='map-data' data-usemap="12">
              <img src="/images/dd/dd12.png" className="w-100 h-100 m-2 p-2" data-slide="12"></img>
              <div className='map-coordinates'>

                  <spin className='mc1-top'>62</spin>
                  <spin className='mc1-left'>12</spin>

                  <spin className='mc1-link'>/decorsdetail/2032-ash-white</spin>
                  <spin className='mc1-title'>2032</spin>

                  <spin className='mc2-top'></spin>
                  <spin className='mc2-left'></spin>
          
                  <spin className='mc2-link'></spin>
                  <spin className='mc2-title'></spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
          
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>

                </div>
            </div>

            <div id="mdd18" className='map-data' data-usemap="18">
              <img src="/images/dd/dd18.png"  className="w-100 h-100 m-2 p-2" data-slide="18"></img>
                <div className='map-coordinates'>

                  <spin className='mc1-top'></spin>
                  <spin className='mc1-left'></spin>

                  <spin className='mc1-link'></spin>
                  <spin className='mc1-title'></spin>

                  <spin className='mc2-top'></spin>
                  <spin className='mc2-left'></spin>
          
                  <spin className='mc2-link'></spin>
                  <spin className='mc2-title'></spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
          
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>

                </div>
            </div>

        </div>
        
        <div className="d-flex flex-column">

            <div id="mdd5" className='map-data' data-usemap="5">
              <img src="/images/dd/dd5.png" className="w-100 h-100 m-2 p-2" data-slide="5"></img>
                <div className='map-coordinates'>

                  <spin className='mc1-top'>36</spin>
                  <spin className='mc1-left'>53</spin>

                  <spin className='mc1-link'>/decorsdetail/s002-noce-perenne</spin>
                  <spin className='mc1-title'>Noce Perenne - S002</spin>

                  <spin className='mc2-top'></spin>
                  <spin className='mc2-left'></spin>
          
                  <spin className='mc2-link'></spin>
                  <spin className='mc2-title'></spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
          
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>

                </div>
            </div>

            <div id="mdd6" className='map-data' data-usemap="6">
              <img src="/images/dd/dd6.png"  className="w-100 h-100 m-2 p-2" data-slide="6"></img>
              <div className='map-coordinates'>

                  <spin className='mc1-top'>51.3</spin>
                  <spin className='mc1-left'>55</spin>

                  <spin className='mc1-link'>/decorsdetail/4022</spin>
                  <spin className='mc1-title'>4022</spin>

                  <spin className='mc2-top'></spin>
                  <spin className='mc2-left'></spin>
          
                  <spin className='mc2-link'></spin>
                  <spin className='mc2-title'></spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
          
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>

                </div>
            </div>

            <div id="mdd13" className='map-data' data-usemap="13">
              <img src="/images/dd/dd13.png" className="w-100 h-100 m-2 p-2" data-slide="13"></img>
                <div className='map-coordinates'>

                  <spin className='mc1-top'></spin>
                  <spin className='mc1-left'></spin>

                  <spin className='mc1-link'></spin>
                  <spin className='mc1-title'></spin>

                  <spin className='mc2-top'></spin>
                  <spin className='mc2-left'></spin>
          
                  <spin className='mc2-link'></spin>
                  <spin className='mc2-title'></spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
          
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>

                </div>
            </div>

            <div id="mdd14" className='map-data' data-usemap="14">
              <img src="/images/dd/dd14.png"  className="w-100 h-100 m-2 p-2" data-slide="14"></img>
                <div className='map-coordinates'>

                  <spin className='mc1-top'></spin>
                  <spin className='mc1-left'></spin>

                  <spin className='mc1-link'></spin>
                  <spin className='mc1-title'></spin>

                  <spin className='mc2-top'></spin>
                  <spin className='mc2-left'></spin>
          
                  <spin className='mc2-link'></spin>
                  <spin className='mc2-title'></spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
          
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>

                </div>
            </div>

            <div id="mdd19" className='map-data' data-usemap="19">
              <img src="/images/dd/dd19.png"  className="w-100 h-100 m-2 p-2" data-slide="19"></img>
              <div className='map-coordinates'>

                  <spin className='mc1-top'>58.5</spin>
                  <spin className='mc1-left'>44</spin>

                  <spin className='mc1-link'>#</spin>
                  <spin className='mc1-title'>1051</spin>

                  <spin className='mc2-top'>14.5</spin>
                  <spin className='mc2-left'>60.5</spin>
          
                  <spin className='mc2-link'>/decorsdetail/2017-cherry-red</spin>
                  <spin className='mc2-title'>2017</spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
          
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>

                </div>
            </div>

            <div id="mdd17" className='map-data' data-usemap="17">
              <img src="/images/dd/dd17.png"  className="w-100 h-100 m-2 p-2" data-slide="17"></img>
                <div className='map-coordinates'>

                  <spin className='mc1-top'>23</spin>
                  <spin className='mc1-left'>54</spin>

                  <spin className='mc1-link'>/decorsdetail/2029-sage-green</spin>
                  <spin className='mc1-title'>2029</spin>

                  <spin className='mc2-top'>77.2</spin>
                  <spin className='mc2-left'>75</spin>
          
                  <spin className='mc2-link'>/decorsdetail/s002-noce-perenne</spin>
                  <spin className='mc2-title'>Noce Perenne - S002</spin>

                  <spin className='mc3-top'></spin>
                  <spin className='mc3-left'></spin>
          
                  <spin className='mc3-link'></spin>
                  <spin className='mc3-title'></spin>

                </div>
            </div>
        </div>

    </div>
</div>

<br></br>

<br></br>

      <div className="centered-div img-popop-dl" data-modal="dbl-02" style={style1}>

          <div className="close" data-modal="dbl-02">x</div>

          <div className='dds-control'>
            <i className="left fa-solid fa-angle-left"></i>
            <i className="right fa-solid fa-angle-right"></i>
          </div>
          
        <center>

          <div class="slide-container">

          <div class="img_viewer relative map">

            <img className='product_image' id="test" src="https://farm4.staticflickr.com/3804/33589584740_b0fbdcd4aa_z.jpg" />


            <div class="absolute" id="popover-btn"></div>
            <div class="absolute" id="popover-btn2"></div>
            <div class="absolute" id="popover-btn3"></div>
            
          </div>

        </div>

        </center>

          

        </div>

      <Footer />
    </>
  )
}

export default OurDesignStudios
