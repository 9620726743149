import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./homdi.css";
import { Carousel } from "react-responsive-carousel";

const Homdi = () => {
  return (
    <Carousel
      infiniteLoop
      autoFocus={false}
      centerMode
      centerSlidePercentage={60}
      showStatus={false}
      showThumbs={false}
      showIndicators={false}
    >
      <a href="/design-diaries" target="_blank">
      <div>
        
        <img src="/images/10.jpg" />
        
      </div>
      </a>

      <a href="/design-diaries" target="_blank">
      <div>
        
        <img src="/images/11.jpg" />
        
      </div>
      </a>

      <a href="/design-diaries" target="_blank">
      <div>
        
        <img src="/images/12.jpg" />
        
      </div>
      </a>

      <a href="/design-diaries" target="_blank">
      <div>
        
        <img src="/images/13.jpg" />
       
      </div>
      </a>
   
    </Carousel>
  );
};

export default Homdi;
